<template>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1161.36 1161.36">
        <path class="cls-2" d="m0,0v1161.36h1161.36V0H0Zm47.38,47.38h1066.6v1066.6H47.38V47.38Z"/>
        <rect class="cls-2" x="128.41" y="844.27" width="900.08" height="16.98"/>
        <rect class="cls-2" x="128.41" y="895.9" width="900.08" height="16.99"/>
        <rect class="cls-2" x="128.41" y="947.55" width="900.08" height="16.98"/>
        <g>
            <path class="cls-2" d="m193.08,801.04c-35.65,0-64.66-27.99-64.66-62.41,0-4.69,3.8-8.5,8.49-8.5s8.49,3.81,8.49,8.5c0,25.05,21.4,45.42,47.69,45.42s47.69-20.37,47.69-45.42c0-21.7-15.84-34.46-25.28-40.14l-39.55-23.31c-11.02-6.79-18.5-17.25-21.17-29.52-2.64-12.05-.31-24.33,6.55-34.61,14.21-21.26,43.71-27.49,65.79-13.91,3.99,2.46,5.24,7.69,2.78,11.68-2.46,3.99-7.69,5.25-11.68,2.78-14.39-8.86-33.57-4.87-42.76,8.87-4.29,6.41-5.74,14.06-4.1,21.55,1.69,7.74,6.44,14.34,13.36,18.6l39.47,23.27c21.36,12.83,33.58,32.76,33.58,54.72,0,34.42-29.01,62.41-64.66,62.41"/>
            <path class="cls-2" d="m567.19,801.05c-35.65,0-64.66-28-64.66-62.41v-141.54c0-4.68,3.8-8.49,8.49-8.49s8.49,3.81,8.49,8.49v141.54c0,25.05,21.4,45.43,47.69,45.43s47.69-20.38,47.69-45.43v-141.54c0-4.68,3.8-8.49,8.49-8.49s8.49,3.81,8.49,8.49v141.54c0,34.41-29.01,62.41-64.66,62.41"/>
            <path class="cls-2" d="m368.43,801.05c-4.69,0-8.49-3.8-8.49-8.49v-194.1c0-4.69,3.8-8.5,8.49-8.5s8.49,3.81,8.49,8.5v194.1c0,4.69-3.8,8.49-8.49,8.49"/>
            <path class="cls-2" d="m303.56,801.05c-4.69,0-8.49-3.8-8.49-8.49v-194.1c0-4.69,3.8-8.5,8.49-8.5s8.49,3.81,8.49,8.5v194.1c0,4.69-3.8,8.49-8.49,8.49"/>
            <path class="cls-2" d="m468.47,801.05c-3.02,0-5.95-1.62-7.48-4.46l-66.79-123.79c-1.64-3.04-1.28-6.76.91-9.43l59.02-71.66c2.98-3.62,8.33-4.14,11.94-1.16,3.62,2.99,4.14,8.33,1.16,11.94l-55.39,67.26,64.08,118.77c2.23,4.13.7,9.28-3.44,11.5-1.27.7-2.66,1.02-4.02,1.02"/>
            <path class="cls-2" d="m789.57,801.05c-3.03,0-5.96-1.62-7.49-4.48l-62.1-115.67c-1.41-2.63-1.33-5.8.2-8.37,1.53-2.56,4.29-4.13,7.29-4.13,18.62,0,33.77-14.39,33.77-32.08s-15.15-32.08-33.77-32.08h-30.65v188.32c0,4.69-3.8,8.49-8.5,8.49s-8.49-3.8-8.49-8.49v-196.81c0-4.69,3.81-8.49,8.49-8.49h39.15c27.98,0,50.75,22,50.75,49.05,0,22.61-15.91,41.71-37.48,47.35l56.3,104.88c2.21,4.14.66,9.28-3.47,11.49-1.28.69-2.65,1.01-4,1.01"/>
            <path class="cls-2" d="m927.41,799.69c-58.2,0-105.54-47.34-105.54-105.54s47.34-105.55,105.54-105.55,105.54,47.35,105.54,105.55-47.34,105.54-105.54,105.54m0-194.1c-48.84,0-88.56,39.73-88.56,88.56s39.73,88.55,88.56,88.55,88.56-39.73,88.56-88.55-39.73-88.56-88.56-88.56"/>
        </g>
        <g>
            <polygon class="cls-2" points="706.95 460.87 706.95 460.87 706.95 460.88 724.67 460.88 724.67 460.87 724.67 460.87 706.95 460.87"/>
            <rect class="cls-2" x="587.35" y="323.55" width="0" height="17.73"/>
            <path class="cls-2" d="m706.95,341.27v119.6h17.73v-128.46c0-4.89-3.97-8.87-8.87-8.87h-128.46v17.73h119.6Z"/>
            <path class="cls-2" d="m432.31,332.41v216.14h17.72v-207.27h119.6v-17.73h-128.46c-4.89,0-8.86,3.97-8.86,8.86Z"/>
            <polygon class="cls-1" points="706.95 478.6 706.95 478.6 706.95 478.6 724.67 478.6 724.68 478.6 724.68 478.6 706.95 478.6"/>
            <path class="cls-2" d="m587.35,323.55v-133.23c0-4.89-3.97-8.86-8.86-8.86h-274.65c-4.89,0-8.86,3.97-8.86,8.86v358.23h17.72V199.17h256.93v349.37h17.72v-69.94h0s0,0,0,0v-17.73h0v-119.6h0v-17.73h0Z"/>
            <path class="cls-2" d="m853.13,460.87h-128.46s-17.73,0-17.73,0h0s-119.6,0-119.6,0h0v17.73h119.6s17.73,0,17.73,0h0s119.6,0,119.6,0v69.95h17.72v-78.82c0-4.89-3.97-8.86-8.86-8.86Z"/>
            <polygon class="cls-2" points="706.95 478.6 706.95 478.6 706.95 548.55 724.68 548.55 724.68 478.6 724.67 478.6 706.95 478.6"/>
            <polygon class="cls-1" points="587.35 460.87 587.34 460.87 587.34 478.6 587.35 478.6 587.35 460.88 587.35 460.87"/>
        </g>
        <path class="cls-2" d="m137.89,83.74c-.35-.06-.26.23-.08.55.87-.23-.27.05.08-.55"/>
    </svg>
</template>
